export const PUTT_LOGO =
  "https://res.cloudinary.com/ddjs9o7wv/image/upload/v1629459370/putt/logo_l7rt1j.png";
export const DEFAULT_CLUB_BANNER_URL =
  "https://res.cloudinary.com/silverstag/image/upload/v1688051616/clubs/default_club_banner.jpg";
export const DEFAULT_CLUB_LOGO_URL =
  "https://res.cloudinary.com/silverstag/image/upload/v1688051610/clubs/default_club_logo.jpg";

export const DATE_FORMAT = "D MMMM YYYY";
export const TIMEZONE = "Africa/Johannesburg";
export const APP_TITLE = "Club Manager";
export const ADMIN_BASE_URL = "/club";

export const INCORRECT_EMAIL_OR_PASSWORD =
  "Incorrect email or password provided.";
export const SERVER_ERROR =
  "There was a problem signing you in, please try again later.";

export const POLLING_INTERVAL = 15000;

export const MAX_HANDICAP = 54;
export const MIN_HANDICAP = -18;

export const SETTINGS_SHOW_LEADERBOARD_TOOLTIP =
  "Allow players to see the round leaderboard in the app";
export const SETTINGS_LIMIT_TV_LEADERBOARD_TOOLTIP =
  "Limit the number of holes viewed on the TV leaderboard.";
export const SETTINGS_HANDICAP_EDIT_TOOLTIP =
  "Allow players to edit their calculated Course Handicap";
export const SETTINGS_REQUIRE_CODE_TOOLTIP =
  "Only allow players with the invite code to join the round";
export const SETTINGS_LEADERBOARD_HOLE_LIMIT_TOOLTIP =
  "Number of holes to show on the leaderboard. E.g a limit of 16 means that only the results of the first 16 holes will be displayed";
export const SETTINGS_CAPTURE_HANDICAP_ALLOWANCE_TOOLTIP =
  "The handicap allowance to apply for the round";
export const SETTINGS_ALLOW_GENDER_SEPARATED_DIVISIONS_TOOLTIP =
  "Allow a gender requirement to be set for each division";

export const SILVERSTAG_LEADERBOARD_FOOTER_ADVERT_URL =
  "https://res.cloudinary.com/silverstag/image/upload/v1683293432/prod/Premium_Software_Development_7_mjhdu9.gif";
export const RAND_RECYCLING_LEADERBOARD_FOOTER_ADVERT_URL =
  "https://res.cloudinary.com/silverstag/image/upload/v1683292636/prod/jkt6gyvzkoupmfippt9f.jpg";

export const USER_NOT_FOUND_ERROR_CODE = "auth/user-not-found";
export const WRONG_PASSWORD_ERROR_CODE = "auth/wrong-password";

export const PASSWORD_RESET_ERROR_MESSAGE =
  "Password reset unsuccessful. Please ensure that the email you have provided is correct.";
export const PASSWORD_RESET_SUCCESS_MESSAGE =
  "Password reset email sent. Please check your inbox for reset instructions.";
