"use client";

import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/old-ui/card/card";
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
  FormControl,
} from "@/components/old-ui/form/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Logo } from "@/components/old-ui/logo/logo";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { signInWithPassword } from "@putt-web/auth/api";
import { useAdminContext } from "../../hooks/AdminContext";
import { useAuthContext } from "@putt-web/auth/context";
import {
  APP_TITLE,
  INCORRECT_EMAIL_OR_PASSWORD,
  SERVER_ERROR,
  USER_NOT_FOUND_ERROR_CODE,
  WRONG_PASSWORD_ERROR_CODE,
} from "../../appConstants";

const FormSchema = z.object({
  email: z.string().email("This is not a valid email.").max(50).min(1),
  password: z.string().min(1, "Password is required.").max(50).min(1),
});

const LoginPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { adminSignIn } = useAdminContext();
  const { logOut } = useAuthContext();

  const router = useRouter();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onLogin = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithPassword(email, password);
      const idToken = await userCredential.user.getIdToken();

      await fetch("/api/login", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      await adminSignIn();
      router.refresh();
    } catch (err) {
      const { code } = err as { code: string };
      if (
        code === WRONG_PASSWORD_ERROR_CODE ||
        code === USER_NOT_FOUND_ERROR_CODE
      ) {
        form.setError("password", { message: INCORRECT_EMAIL_OR_PASSWORD });
      } else {
        form.setError("email", { message: SERVER_ERROR });
        await logOut();
      }
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (values: z.infer<typeof FormSchema>) => {
    setIsSubmitting(true);

    await onLogin(values.email, values.password);
  };

  return (
    <div className="w-full h-screen h-100 flex justify-center items-center">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card className="w-96">
            <CardHeader className="flex flex-col items-center gap-4">
              <Logo size="lg" />
              <CardTitle className="heading-lg">{APP_TITLE}</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-8">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="Email address" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="Password"
                          type="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </CardContent>
            <CardFooter className="flex flex-col items-center gap-1">
              <Button
                type="submit"
                size="lg"
                className="w-full"
                disabled={isSubmitting}
              >
                Login
              </Button>
              <Button variant="link" className="caption-md p-0">
                <Link href="/forgot-password">Forgot password?</Link>
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  );
};

export default LoginPage;
