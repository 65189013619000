import React from "react";
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const logoVariants = cva("", {
  variants: {
    size: {
      xs: "w-10",
      sm: "w-14",
      md: "w-28",
      lg: "w-40",
      xl: "w-52",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export interface LogoProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof logoVariants> {
  src?: string;
}

const Logo = React.forwardRef<HTMLDivElement, LogoProps>(
  ({ className, src = "/logo.png", size, ...props }, ref) => {
    return (
      <div
        className={cn("flex items-center justify-center", className)}
        ref={ref}
        {...props}
      >
        <img src={src} alt="Putt Logo" className={cn(logoVariants({ size }))} />
      </div>
    );
  },
);

export { Logo, logoVariants };
