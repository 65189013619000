"use client";

import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useClubAdminSignInMutation } from "../generated/graphql";

interface IAdminContext {
  clubId: string | null;
  adminSignIn: () => Promise<any>;
}

export const AdminContext = createContext<IAdminContext>({
  clubId: null,
  adminSignIn: async () => null,
});

export const useAdminContext = () => useContext(AdminContext);

interface AdminContextProps {
  children: ReactNode;
}

const AdminContextProvider = ({ children }: AdminContextProps) => {
  const [clubAdminSignIn] = useClubAdminSignInMutation();
  const [clubId, setClubId] = useState<string | null>(() => {
    if (typeof window === "undefined") {
      return null;
    }

    const storedClubId = localStorage.getItem("clubId");
    return storedClubId ? storedClubId : null;
  });

  const adminSignIn = useCallback(async () => {
    try {
      const { data } = await clubAdminSignIn();

      if (typeof window !== "undefined") {
        localStorage.setItem("clubId", data?.clubAdminSignIn.club.id);
        setClubId(data?.clubAdminSignIn.club.id);
      }

      return data;
    } catch {
      return null;
    }
  }, [clubAdminSignIn]);

  const value = useMemo(
    () => ({
      clubId,
      adminSignIn,
    }),
    [adminSignIn, clubId],
  );

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};

export default AdminContextProvider;
